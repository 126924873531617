import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Surface Texturing",
  "description": null,
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Polishing", "IMD-Lamination", "PVD"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In applications where a product’s grip or reflective capabilities need to be controlled, adding textures to a flat surface could help increase friction and reduce reflections. The process can be done by surface texturing. Surface texturing alters the texture of a product’s surface by adding UV or textured films or by using chemical etching or photoetching. Before undergoing the surface texturing process, sanding must be done on the surface to create an even and smooth surface.`}</p>
    <p>{`PET, PC, or a combination of PC+PMMA boards are often processed with UV film applications. These UV films are applied by glue to the surface and give the product surface light streaks or reflective and gradient colors to the workpiece.`}</p>
    <p>{`Chemical photo etching is applicable for plastics, metals, and alloys. The process commonly uses ferric chloride, which is recyclable (hence more economical), or nitric acid, which is a specialist for metals and alloys.`}</p>
    <p>{`The chemical etching process is done by:`}</p>
    <ol>
      <li parentName="ol">{`Laminating the workpiece with a photoresist layer`}</li>
      <li parentName="ol">{`A UV light shines on the predesigned area to stick the photoresist layer onto the piece`}</li>
      <li parentName="ol">{`Remove the excess layer, and pour the etching chemical on the working surface`}</li>
      <li parentName="ol">{`The exposed material, i.e., areas where the photoresist film is removed, then corrodes. The texture is then generated`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      